<template>
  <div class="orderDetails">
    <template>
      <div class="baseInfo">
        <p class="pub_head">学生基本信息</p>
        <div class="info_area">
          <ul>
            <li><span>姓名:</span> <span>{{orderDetailsData.name}}</span></li>
            <li><span>教学点:</span> <span>{{orderDetailsData.stationName}}</span></li>
          </ul>
          <ul>
            <li><span>学号:</span> <span>{{orderDetailsData.studentNum}}</span></li>
            <li><span>省份:</span> <span>{{orderDetailsData.province}}</span></li>
          </ul>
          <ul>
            <li><span>批次:</span> <span>{{orderDetailsData.admissionBatch}}</span></li>
            <li><span>学校:</span> <span>{{orderDetailsData.schoolName}}</span></li>
          </ul>
          <ul>
            <li v-if="orderDetailsData.avatarUrl">
              <img :src="orderDetailsData.avatarUrl" alt="" srcset="">
            </li>
          </ul>
        </div>
      </div>
      <div class="payInfo info">
        <p class="pub_head">订单信息</p>
        <div class="pub_cont">
          <el-descriptions title="支付信息" class="sub_title">
            <el-descriptions-item label="收费单价">{{orderDetailsData.unitPrice || '/'}}</el-descriptions-item>
            <el-descriptions-item label="实收单价">{{orderDetailsData.actualUnitPrice || '/'}}</el-descriptions-item>
            <el-descriptions-item label="费用类型">{{$common.payAuditData[orderDetailsData.feeType] || '/'}}</el-descriptions-item>
            <el-descriptions-item label="缴费状态">{{$common.statusData[orderDetailsData.status] || '/'}}</el-descriptions-item>
            <el-descriptions-item label="缴费方式">{{$common.payTypeData[orderDetailsData.payType] || '/'}}</el-descriptions-item>
            <el-descriptions-item label="支付平台">{{$common.spTypeData[orderDetailsData.spType] || '/'}}</el-descriptions-item>
            <el-descriptions-item label="学习权限">{{$common.learningPermissionsData[orderDetailsData.learningPermissions] || '/'}}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{timestampReturnDate(orderDetailsData.createTime, 'datetime')}}</el-descriptions-item>
            <el-descriptions-item label="缴费时间">{{timestampReturnDate(orderDetailsData.payDate, 'datetime')}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="开票信息" class="sub_title">
            <el-descriptions-item label="开票状态">{{$common.invoiceStatusData[orderDetailsData.invoiceFlag] || '/'}}</el-descriptions-item>
            <el-descriptions-item label="开票形式">{{$common.invoicingMethodData[orderDetailsData.invoiceMethod] || '/'}}</el-descriptions-item>
            <el-descriptions-item label="开票金额">{{orderDetailsData.invoiceAmount || '/'}}</el-descriptions-item>
            <el-descriptions-item label="公司名称">{{orderDetailsData.headName || '/'}}</el-descriptions-item>
            <el-descriptions-item label="公司税号">{{orderDetailsData.dutyParagraph || '/'}}</el-descriptions-item>
            <el-descriptions-item label="开票时间">{{timestampReturnDate(orderDetailsData.invoiceImportTime, 'datetime') || '/'}}</el-descriptions-item>
            <el-descriptions-item label="发送邮箱">{{orderDetailsData.email || '/'}}</el-descriptions-item>
          </el-descriptions>
          <!-- <el-descriptions title="付款信息" class="sub_title" v-if="this.paytype == '0' && this.status == '1'">
            <el-descriptions-item label="支付金额">{{orderDetailsData.spAmount}}</el-descriptions-item>
            <el-descriptions-item label="支付平台">{{orderDetailsData.spType}}</el-descriptions-item>
            <el-descriptions-item label="支付时间">{{timestampReturnDate(orderDetailsData.paySuccessTime, 'datetime')}}</el-descriptions-item>
            <el-descriptions-item label="支付场景">{{orderDetailsData.sceneFlag}}</el-descriptions-item>
            <el-descriptions-item label="交易号">{{orderDetailsData.tradeNo}}</el-descriptions-item>
            <el-descriptions-item label="商户订单号">{{orderDetailsData.outTradeNo}}</el-descriptions-item>
          </el-descriptions> -->
        </div>
      </div>
      <div class="payInfo info">
        <p class="pub_head">操作信息</p>
        <div class="pub_cont">
          <el-descriptions title="载入信息" class="sub_title">
            <el-descriptions-item label="载入时间">{{timestampReturnDate(orderDetailsData.createTime, 'datetime')}}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="审核信息" class="sub_title" v-if="orderDetailsData.status == 1">
            <el-descriptions-item label="审核人" v-if="this.paytype == '1'">{{orderDetailsData.reviewerSName}}</el-descriptions-item>
            <el-descriptions-item label="审核时间" v-if="this.paytype == '1'">{{timestampReturnDate(orderDetailsData.reviewTime, 'datetime')}}</el-descriptions-item>
            <el-descriptions-item label="审核结果">{{this.paytype == '0' ? '系统审核通过' : '通过'}}</el-descriptions-item>
            <!-- <el-descriptions-item label="审核结果">{{orderDetailsData.auditResults == 1 ? '系统审核通过' : '通过'}}</el-descriptions-item> -->
          </el-descriptions>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import way from '../../api/encapsulation'
export default {
  name: 'orderDetails',
  data() {
    return {
      id: '',
      paytype: '',
      status: '',
      orderDetailsData: {},
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.id = newVal.query.id;
        this.getOrderDetail();
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    getOrderDetail() {
      this.$request.checkStatus({id: this.id}, res => {
        if(res.code == 0){
          this.paytype = String(res.data.payType);
          this.status = String(res.data.status);
          this.orderDetailsData = res.data;
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    }
  },
}
</script>

<style lang="less">
  .orderDetails {
    height: calc(~'100% - 68px');
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 40px;
    .info {
      margin-top: 20px;
      border: 1px solid #F2F2F2;
    }
    .pub_head {
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      padding-left: 29px;
      background: #F2F2F2;;
      font-size: 18px;
      font-weight: 600;
       
    }
    .pub_cont {
      box-sizing: border-box;
      padding: 20px 40px;
      .sub_title {
        .el-descriptions__title {
          color: #1282FF;
          border-left: 3px solid #1282FF;
          padding-left: 11px;
          font-weight: 500;
        }
      }
      .small_title {
        color: #1282FF;
        border-left: 3px solid #1282FF;
        padding-left: 11px;
        margin-bottom: 20px;
      }
    }
    .baseInfo {
      .info_area {
        display: flex;
        padding-top: 20px;
        background: #FFFFFF;
        border: 1px solid #F2F2F2;
        & > ul {
          margin-left: 40px;
          img {
            width: 100px;
            height: 140px;
          }
        }
      }
    }
    .payInfo {
      .pay_area {

      }
    }
    ul {
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      list-style-type: none;
      flex: 1;
      li {
        text-align: left;
        margin-bottom: 30px;
        span:nth-of-type(1) {
          font-size: 14px;
          display: inline-block;
          width: 60px;
          text-align: right;
        }
        span:nth-of-type(2) {
          margin-left: 10px;
          display: inline-block;
          // width: 100px;
          font-size: 14px;
          font-weight: 400;
          color: #454545;
        }
      }
    }
  }
</style>